import React, { useEffect, useState } from "react";
//import { useParams } from "react-router-dom";
import HomeSidebar from "../components/HomeSidebar/Sidebar";
import Cards from "../components/Cards/Cards";
import { Product } from "../types/types";
import { useStore } from "../../context/StoreContext";
import { fetchProducts } from "../api/MarketApiClient";

const ProductListingPage: React.FC = () => {
 // const { categoryName } = useParams<{ categoryName: string }>();
  const { selectedStore, selectedCategory, selectedFilters, setSelectedFilters } = useStore();
  const [products, setProducts] = useState<Product[]>([]);

  useEffect(() => {
    if (!selectedStore || !selectedCategory) return;

    const fetchFilteredProducts = async () => {
      const filters: Record<string, string | string[]> = {
        status: '1',
        visibility: '4',
        currentPage: '1',
        ...selectedFilters,
      };

      try {
        const fetchedProducts = await fetchProducts(
          selectedStore.code,
          selectedCategory.id.toString(),
          filters
        );
        setProducts(fetchedProducts);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    fetchFilteredProducts();
  }, [selectedStore, selectedCategory, selectedFilters]);

  const updateFilters = (filters: { [attributeCode: string]: string[] }) => {
    setSelectedFilters(filters);
  };

  if (!selectedStore) return <div>Please select a store</div>;

  return (
    <div className="w-full">
      <div className="section w-full flex flex-col md:flex-row items-start justify-start gap-4 md:gap-10 lg:gap-12 px-4 md:px-8 lg:px-20 text-left text-xs md:text-sm lg:text-base">
        <HomeSidebar updateFilters={updateFilters} />
        <Cards products={products} category={selectedCategory} storeCode={selectedStore.code} />
      </div>
    </div>
  );
};

export default ProductListingPage;
