import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import pageLeft from '../../assets/sehaa_plp_clp/page-left.svg';
import pageRight from '../../assets/sehaa_plp_clp/page-right.svg';
import CaretRight from '../../assets/sehaa_plp_clp/CaretRight.svg';
import { Product, Category } from '../../types/types';
import { fetchCategories, fetchProducts } from '../../api/MarketApiClient';
import { useStore } from '../../../context/StoreContext';
import ProductCard from './ProductCard';

interface CardsProps {
  products: Product[];
  category: Category | null;
  storeCode: string;
}

const Cards: React.FC<CardsProps> = ({ products, category, storeCode }) => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [filter, setFilter] = useState<string>('All Products');
  const [subCategories, setSubCategories] = useState<Category[]>([]);
  const [filteredProducts, setFilteredProducts] = useState<Product[]>(products);
  const { setSelectedProduct, selectedFilters } = useStore();
  const [sortOption, setSortOption] = useState<string>('Name A to Z');
  const itemsPerPage = 16;
  const totalPages = Math.ceil(filteredProducts.length / itemsPerPage);

  const paginatedProducts = filteredProducts.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  // useEffect(() => {
  //   setFilteredProducts(products);
  //   setCurrentPage(1);
  // }, [products]);

  useEffect(() => {
    if (!storeCode || !category) return;

    const initializeCategories = async () => {
      try {
        const categoriesData = await fetchCategories(storeCode);
        if (category) {
          const selectedCategoryData = categoriesData.children_data.find(cat => cat.name === category.name);
          if (selectedCategoryData) {
            const thirdLevelCategories = selectedCategoryData.children_data?.filter(cat => cat.level === 3) || [];
            setSubCategories(thirdLevelCategories);
          }
        }
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };

    initializeCategories();
  }, [storeCode, category]);

  useEffect(() => {
    applyFilters();
    setFilter('All Products'); // Reset to 'All Products' when filters change
  }, [selectedFilters, products]);

  const applyFilters = async () => {
    if (!category) return;

    try {
      const filteredProducts = await fetchProducts(storeCode, category.id.toString(), selectedFilters);
      setFilteredProducts(filteredProducts);
      setCurrentPage(1);
    } catch (error) {
      console.error('Error fetching filtered products:', error);
    }
  };

  const navigate = useNavigate();

  const handleClick = (product: Product) => {
    setSelectedProduct(product);
    navigate(`/product/${product.url_key}`);
  };

  const handleFilterChange = async (filterType: string, categoryId?: number) => {
    setFilter(filterType);
    setCurrentPage(1);

    if (filterType === 'All Products') {
      applyFilters(); // This will fetch all products with current filters
    } else if (categoryId) {
      try {
        const subCategoryProducts = await fetchProducts(storeCode, categoryId.toString(), selectedFilters);
        setFilteredProducts(subCategoryProducts);
      } catch (error) {
        console.error('Error fetching sub-category products:', error);
      }
    }
  };


  const handleSortChange = (option: string) => {
    setSortOption(option);
    setCurrentPage(1);

    const sortedProducts = [...filteredProducts]; // Create a copy to avoid mutation

    if (option === 'Name A to Z') {
        sortedProducts.sort((a, b) => a.name.localeCompare(b.name));
    } else if (option === 'Name Z to A') {
        sortedProducts.sort((a, b) => b.name.localeCompare(a.name));
    } else if (option === 'Price low to high') {
        sortedProducts.sort((a, b) => parseFloat(a.price) - parseFloat(b.price));
    } else if (option === 'Price high to low') {
        sortedProducts.sort((a, b) => parseFloat(b.price) - parseFloat(a.price));
    }
    setFilteredProducts(sortedProducts);
};

  return (
    <div className="w-full cards-container mx-auto px-2 sm:px-4 md:px-6 lg:px-8" style={{ maxWidth: 'calc(240px * 4 + 20px * 3 + 1rem)' }}>
      {/* Navbar */}
      <div className="w-full flex flex-col gap-4 mb-6 sm:mb-8">
        <div className="flex flex-col gap-3 sm:gap-4 w-full">
          <div className="flex flex-col gap-1 sm:gap-2 w-full">
            <div className="text-lg sm:text-xl md:text-2xl font-semibold leading-tight">{category?.name || 'All Products'}</div>
            <div className="flex items-center gap-1 text-xs text-gray-400">
              <div className="leading-tight">Home</div>
              <img className="w-2 h-2 sm:w-2.5 sm:h-2.5" alt="" src={CaretRight} />
              <div className="leading-tight">{category?.name || 'All Products'}</div>
            </div>
          </div>
          <div className="w-full border-b border-gray-200 overflow-x-auto scrollbar-hide">
            <div className="flex items-center gap-3 sm:gap-4 md:gap-6 lg:gap-8 text-center whitespace-nowrap">
              <div 
                className={`flex items-center justify-center pb-2 cursor-pointer ${filter === 'All Products' ? 'border-b-[4px] border-primary font-bold' : ''}`} 
                onClick={() => handleFilterChange('All Products')}
              >
                <div className="leading-6">All Products</div>
              </div>
              {subCategories.map(cat => (
                <div 
                  key={cat.id} 
                  className={`flex items-center justify-center pb-2 cursor-pointer ${filter === cat.name ? 'border-b-[4px] border-primary font-bold' : ''}`} 
                  onClick={() => handleFilterChange(cat.name, cat.id)}
                >
                  <div className="leading-6">{cat.name}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="w-full flex flex-col sm:flex-row justify-between items-start sm:items-center text-xs sm:text-sm gap-2">
          <div>Items {currentPage * itemsPerPage - itemsPerPage + 1}-{Math.min(currentPage * itemsPerPage, filteredProducts.length)} of {filteredProducts.length}</div>
          <div className="flex items-center gap-2 text-xs">
            Sort by:
            <select 
              value={sortOption} 
              onChange={(e) => handleSortChange(e.target.value)} 
              className="border border-gray-300 bg-transparent rounded-md px-2 py-1"
            >
              <option>Name A to Z</option>
              <option>Name Z to A</option>
              <option>Price low to high</option>
              <option>Price high to low</option>
            </select>
          </div>
        </div>
      </div>

      {/* Cards Grid */}
      <div className="grid grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-2 sm:gap-4 md:gap-5 mb-8 sm:mb-12 justify-items-center">
        {paginatedProducts.map((product: Product) => (
          <ProductCard
            key={product.id}
            product={product}
            onClick={() => handleClick(product)}
          />
        ))}
      </div>

      {filteredProducts.length === 0 && (
        <div className="text-center text-gray-500 py-8">
          No products found. Try adjusting your filters or selecting a different category.
        </div>
      )}

      {/* Pagination */}
      {filteredProducts.length > 0 && (
        <div className="flex items-center justify-center py-4 sm:py-5 mt-auto">
          {currentPage > 1 && (
            <button
              className="m-0 p-0 mr-2"
              onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
            >
              <img className="w-full h-full" alt="Previous" src={pageLeft} />
            </button>
          )}

          {Array.from({ length: totalPages }, (_, index) => (
            <button
              key={index + 1}
              className={`w-6 h-6 sm:w-8 sm:h-8 flex items-center justify-center rounded-sm mx-1 ${
                currentPage === index + 1 ? 'bg-[#f05f6c] text-white' : 'text-gray-600'
              }`}
              onClick={() => setCurrentPage(index + 1)}
            >
              <div className="font-semibold text-sm sm:text-base">{index + 1}</div>
            </button>
          ))}

          {currentPage < totalPages && (
            <button
              className="m-0 p-0 ml-2"
              onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
            >
              <img className="w-full h-full" alt="Next" src={pageRight} />
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default Cards;