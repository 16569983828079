//import React, { useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Authenticator, useAuthenticator } from "@aws-amplify/ui-react";
import { StoreProvider } from "./context/StoreContext";
import HomePage from "./market/pages/HomePage";
import ProductDetailsPage from "./market/pages/ProductDetailsPage";
import NavbarOptions from "./market/components/NavbarOptions/NavbarOptions";
import Header from "./market/components/Header/Header";
import Navbar from "./market/components/Navbar/Navbar";
import Footer from "./market/components/Footer/Footer";
import ProductListingPage from "./market/pages/ProductListingPage";

const ProtectedRoute = ({ children }: { children: React.ReactNode }) => {
  const { route } = useAuthenticator((context) => [context.route]);

  if (route !== "authenticated") {
    return (
      <Authenticator>
        {({ signOut }) => (
          <div>
            {children}
            <button onClick={signOut}>Sign Out</button>
          </div>
        )}
      </Authenticator>
    );
  }

  return <>{children}</>;
};

export function App() {
  return (
    <StoreProvider>
      <div className="relative bg-white w-full overflow-y-auto flex flex-col items-center justify-start gap-12 min-h-screen text-center text-xs text-gray-800 font-sora">
        <BrowserRouter>
          <div className="w-full">
            <Header /> 
            <Navbar />
            <NavbarOptions />
          </div>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/categories/:categoryName" element={<ProductListingPage />} />
            <Route
              path="/product/:urlKey"
              element={
                <ProtectedRoute>
                  <ProductDetailsPage />
                </ProtectedRoute>
              }
            />
          </Routes>
          <div className="w-full bg-gray-100 flex flex-col items-start justify-start gap-5">
            <Footer />
          </div>
        </BrowserRouter>
      </div>
    </StoreProvider>
  );
}

export default App;
