import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Category } from '../../types/types';
import { useStore } from '../../../context/StoreContext';

const NavbarOptions: React.FC = () => {
  const { selectedStore, categories, setSelectedCategory, setSelectedFilters } = useStore();
  const [filteredCategories, setFilteredCategories] = useState<Category[]>([]);
  const navigate = useNavigate();

  const desiredCategoryNames: string[] = [
    'bedroom safety', 'bathroom safety', 'mobility aids', 'orthopedic supports',
    'compression garments', 'respiratory care', 'active daily living - adl',
    'used products', 'rent now', 'special prices'
  ];

  useEffect(() => {
    if (!categories.length) return;

    const levelTwoCategories = categories.filter((category: Category) => category.level === 2);
    const filtered = levelTwoCategories.filter(
      (category: Category) => desiredCategoryNames.includes(category.name.toLowerCase())
    );

    const sorted = filtered.sort(
      (a: Category, b: Category) =>
        desiredCategoryNames.indexOf(a.name.toLowerCase()) - desiredCategoryNames.indexOf(b.name.toLowerCase())
    );

    setFilteredCategories(sorted);
  }, [categories]);

  const handleCategoryClick = (category: Category) => {
    setSelectedCategory(category);
    setSelectedFilters({}); // Reset filters when changing category
    navigate(`/categories/${category.name.toLowerCase().replace(/\s+/g, '-')}`);
  };

  if (!selectedStore) return null;

  return (
    <div className="w-full flex flex-col items-start justify-start p-2.5 px-4 sm:px-5 md:px-10 lg:px-15">
      <div className="w-full flex flex-row flex-wrap items-center justify-between px-5">
        {filteredCategories.map((category: Category) => (
          <div
            key={category.id}
            className="relative leading-6 uppercase font-semibold cursor-pointer hover:text-pink-500 transition-colors duration-200"
            onClick={() => handleCategoryClick(category)}
          >
            {category.name}
          </div>
        ))}
      </div>
    </div>
  );
};

export default NavbarOptions;